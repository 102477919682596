import { useDispatch } from 'react-redux';
import { useFormContext, Controller } from 'react-hook-form';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import { dynamicFormActions } from 'features/dynamicForm/store/dynamicFormSlice';
import { useValidation } from 'features/dynamicForm/hooks/useValidation';
import { useAutofill } from 'features/dynamicForm/hooks/useAutofill';
import { useIsShown } from 'features/dynamicForm/hooks/useIsShown';
import { useIsRequired } from 'features/dynamicForm/hooks/useIsRequired';
import { dropdownFieldType } from 'features/dynamicForm/types';

export default function DropdownField({ field }) {
    const {
        control,
        formState: { errors },
    } = useFormContext();
    const dispatch = useDispatch();

    useAutofill(field);
    const validation = useValidation(field);
    const isShown = useIsShown(field);
    const isRequired = useIsRequired(field);

    if (!isShown) {
        return null;
    }

    return (
        <FormControl required={isRequired} error={!!errors[field.key]}>
            <InputLabel id={'label_' + field.key} shrink>
                {field.label}
            </InputLabel>
            <Controller
                control={control}
                name={field.key}
                rules={validation}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                        <Select
                            id={'input_' + field.key}
                            name={field.key}
                            label={field.label}
                            labelId={'label_' + field.key}
                            inputProps={{ readOnly: field.readonly }}
                            onChange={(e) => {
                                onChange(e);
                                dispatch(dynamicFormActions.setValue({ key: field.key, value: e.target.value }));
                            }}
                            value={value ?? ''}
                            notched
                        >
                            {!isRequired && (
                                <MenuItem key={'option_null'} value={undefined}>
                                    <em>None</em>
                                </MenuItem>
                            )}
                            {field.options.map((option) => (
                                <MenuItem key={'option_' + option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                        {error && <FormHelperText>{error.message}</FormHelperText>}
                    </>
                )}
            />
        </FormControl>
    );
}

DropdownField.propTypes = {
    field: dropdownFieldType,
};
