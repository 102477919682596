import React from 'react';

import DataGrid, {
    GroupPanel,
    Paging,
    SearchPanel,
    FilterRow,
    HeaderFilter,
    // Editing,
    Export,
} from 'devextreme-react/data-grid';

// TODO: Add component prop-types. Disabling the eslint rule for now.
/* eslint-disable react/prop-types */
function Table(
    {
        classes,
        data,
        columns,
        rowsPerPage,
        optionChangedHandler,
        disabled,
        focusedRowKey,
        onDoubleClick,
        searchText,
        showSearchPanel = true,
        groupPanel = true,
        filterRow = true,
        exportData = true,
        showColumnLines = true,
        showRowLines = true,
        showBorders = true,
        headerFilter = true,
        rowAlternationEnabled = true,
    },
    ref
) {
    const focusedRow = focusedRowKey ? { focusedRowKey } : null;

    return (
        <DataGrid
            ref={ref}
            disabled={disabled}
            dataSource={data}
            keyExpr="id"
            allowColumnReordering={false}
            showColumnLines={showColumnLines}
            showRowLines={showRowLines}
            showBorders={showBorders}
            focusedRowEnabled={true}
            allowColumnResizing={true}
            columnResizingMode="widget"
            columnAutoWidth={true}
            rowAlternationEnabled={rowAlternationEnabled}
            loadPanel={{ enabled: true }}
            onOptionChanged={optionChangedHandler}
            {...focusedRow}
            className={classes}
            onRowDblClick={onDoubleClick}
        >
            <GroupPanel visible={groupPanel} />
            <SearchPanel visible={showSearchPanel} />
            <Paging defaultPageSize={rowsPerPage} mode="full" showNavigtionButtons={true} />

            <FilterRow visible={filterRow} />
            <HeaderFilter visible={headerFilter} />
            {/* <Editing mode="row" useIcons={true} allowUpdating={true} /> */}
            {columns}

            <Export enabled={exportData} allowExportSelectedData={false} />
        </DataGrid>
    );
}

export default React.forwardRef(Table);
