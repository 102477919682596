import PropTypes from 'prop-types';
import styles from './Section.module.css';
import Field from 'features/dynamicForm/components/Field/Field';
import { sectionType } from 'features/dynamicForm/types';

export default function Section({ section, dynamicFormId }) {
    function generateKey(key) {
        return (dynamicFormId ?? '_') + '_' + key;
    }

    return (
        <section className={styles['section']}>
            <div className={styles['section-header']}>
                <p>{section.title}</p>
            </div>
            <div className={styles['inputs']}>
                {section?.sections?.map((section) => {
                    return <Section key={generateKey(section.key)} section={section} dynamicFormId={dynamicFormId} />;
                })}

                {section?.fields?.map((field) => {
                    return <Field key={generateKey(field.key)} field={field} />;
                })}
            </div>
        </section>
    );
}

Section.propTypes = {
    section: sectionType,
    dynamicFormId: PropTypes.number,
};
