import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { Button } from '@mui/material';
import Section from 'features/dynamicForm/components/Section/Section';
import { dynamicFormActions } from 'features/dynamicForm/store/dynamicFormSlice';
import { dynamicFormType } from 'features/dynamicForm/types';

export default function ViewDynamicForm({ dynamicForm }) {
    const methods = useForm({ shouldUnregister: true });
    const dispatch = useDispatch();

    const onSubmit = (data) => {
        console.log('Submit data', data);
        alert(JSON.stringify(data, null, 2));
    };

    useEffect(() => {
        methods.reset();
        dispatch(dynamicFormActions.clearValues());
    }, [dispatch, methods, dynamicForm]);

    return (
        <div>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
                    {dynamicForm?.form?.sections.map((section) => {
                        return <Section key={dynamicForm.id + '_' + section.key} section={section} dynamicFormId={dynamicForm.id} />;
                    })}
                    <Button variant="contained" color="success" size="small" type="submit">
                        submit
                    </Button>
                </form>
            </FormProvider>
        </div>
    );
}

ViewDynamicForm.propTypes = {
    dynamicForm: dynamicFormType,
};
