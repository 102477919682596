import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styles from './ViewFieldsTemplate.module.css';
import { useForm, FormProvider } from 'react-hook-form';
import { Button } from '@mui/material';
import Field from 'features/dynamicForm/components/Field/Field';
import { dynamicFormActions } from 'features/dynamicForm/store/dynamicFormSlice';
import { fieldsTemplateType } from 'features/dynamicForm/types';

export default function ViewFieldsTemplate({ fieldsTemplate }) {
    const methods = useForm({ shouldUnregister: true });
    const dispatch = useDispatch();

    const onSubmit = (data) => {
        console.log('Submit data', data);
        alert(JSON.stringify(data, null, 2));
    };

    useEffect(() => {
        methods.reset();
        dispatch(dynamicFormActions.clearValues());
    }, [dispatch, methods, fieldsTemplate]);

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
                <section className={styles['section']}>
                    <div className={styles['inputs']}>
                        {fieldsTemplate?.form?.map((field) => {
                            return <Field key={field.key} field={field} />;
                        })}
                    </div>
                </section>
                <Button variant="contained" color="success" size="small" type="submit">
                    submit
                </Button>
            </form>
        </FormProvider>
    );
}

ViewFieldsTemplate.propTypes = {
    fieldsTemplate: fieldsTemplateType,
};
