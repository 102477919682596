import { Dialog, Divider, Stack, Typography } from '@mui/material';
import CustomButton from 'components/UI/buttons/CustomButton.style.js';
import PropTypes from 'prop-types';
import { StyledCloseIcon } from './ConfirmStyles';

const Confirm = ({ open, onCancel, onConfirm, title, message }) => {
    return (
        <Dialog open={open} onClose={onCancel}>
            <Stack padding={1.5} direction={'row'} justifyContent={'space-between'} backgroundColor={'primary.main'}>
                <Typography fontWeight={'Bold'} color={'primary.contrastText'}>
                    {title}
                </Typography>
                <StyledCloseIcon onClick={onCancel} />
            </Stack>
            <Stack padding={2} minHeight={100}>
                {typeof message == 'function' ? message : <Typography>{message}</Typography>}
            </Stack>
            <Divider />
            <Stack paddingX={2} paddingY={2} gap={2} direction="row" justifyContent="flex-end">
                <CustomButton customVariant="error" onClick={onCancel}>
                    No
                </CustomButton>
                <CustomButton customVariant="success" onClick={onConfirm}>
                    Yes
                </CustomButton>
            </Stack>
        </Dialog>
    );
};

export default Confirm;

Confirm.propTypes = {
    open: PropTypes.bool.isRequired,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    title: PropTypes.string,
    message: PropTypes.string,
};
