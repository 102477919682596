import { axiosLasta } from 'config';
import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { FormSaga } from 'store/formControl/formPeopleEmployeeSaga';
import notificationsHandler from 'utils/notificationsHandler';
import { throttleAction } from 'utils/sagaHelpers';

import { fetchAuthTypeList } from 'services/authorizeService';
import { controlCentarActions } from 'store/controlCentar/controlCentarSlice';
import { getFormattedErrorMessageFromErrorObject } from 'utils/formatErrorCode';
import { transformAuthorizedPersons } from './peopleAuthorizedListMapper';
import { peopleAuthorizedListActions } from './peopleAuthorizedListSlice';
import { fetchAuthorizableClientPersonnel } from 'services/clientPersonnelService';
import { getAllLocationList } from 'services/locationService';
import { selectSelectedLocationId } from 'components/globalLocationSelector/store/globalLocationSelectors';

export function* fetchAllLocations() {
    try {
        const data = yield getAllLocationList();
        data.sort(function (a, b) {
            var textA = a?.yardName?.toUpperCase();
            var textB = b?.yardName?.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        yield put(peopleAuthorizedListActions.setAllLocations(data));
        yield put(controlCentarActions.setLocationValues(data));
    } catch (err) {
        const formattedError = getFormattedErrorMessageFromErrorObject(err);
        yield notificationsHandler({ title: formattedError });
    }
}

export function* onFetchAllLocations() {
    yield throttleAction(peopleAuthorizedListActions.fetchAllLocations.type, fetchAllLocations);
}

export function* fetchAuthorizedPersonPerLocation({ payload: location }) {
    try {
        if (!location) {
            yield put(peopleAuthorizedListActions.fetchAuthorizedLocationSuccess([]));
            return;
        }
        const response = yield axiosLasta(`/client/access-personnel/location/${location.id}`);
        const { data } = response;

        const modifiedAuthorizedList = transformAuthorizedPersons(data);

        yield put(peopleAuthorizedListActions.fetchAuthorizedLocationSuccess(modifiedAuthorizedList));
    } catch (err) {
        yield put(peopleAuthorizedListActions.submitFormFailed(err));
        const formattedError = getFormattedErrorMessageFromErrorObject(err);
        yield notificationsHandler({ title: formattedError });
    }
}

export function* onFetchingAuthorizedPersonPerLocation() {
    yield throttleAction(peopleAuthorizedListActions.fetchAuthorizedPersonPerLocation.type, fetchAuthorizedPersonPerLocation);
}

export function* onSubmitFormStart() {
    yield takeLatest(peopleAuthorizedListActions.submitFormStart, FormSaga);
}

export function* fetchPerson() {
    try {
        const selectedLocationId = yield select(selectSelectedLocationId);
        const data = yield fetchAuthorizableClientPersonnel(selectedLocationId);
        data.sort(function (a, b) {
            var textA = a?.firstName?.toUpperCase();
            var textB = b?.firstName?.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        const adjustedData = data.map((el) => {
            return { ...el, fullName: `${el.firstName} ${el.lastName}` };
        });

        yield put(peopleAuthorizedListActions.fetchPersonSuccess(adjustedData));
    } catch (err) {
        yield put(peopleAuthorizedListActions.fetchPersonError(err));
        const formattedError = getFormattedErrorMessageFromErrorObject(err);
        yield notificationsHandler({ title: formattedError });
    }
}

export function* onFetchingPersonsStart() {
    yield throttleAction(peopleAuthorizedListActions.fetchPersonStart.type, fetchPerson);
}

export function* onAddPerson() {
    yield takeLatest(peopleAuthorizedListActions.addPerson.type, FormSaga);
}
export function* onAddSelectedPersonToAuthorizedList() {
    yield takeLatest(peopleAuthorizedListActions.addAuthorizedPerson.type, FormSaga);
}

export function* fetchAllAuthTypes() {
    try {
        const data = yield call(fetchAuthTypeList);
        yield put(peopleAuthorizedListActions.setAuthTypeList(data));
    } catch (err) {
        const formattedError = getFormattedErrorMessageFromErrorObject(err);
        yield notificationsHandler({ title: formattedError });
    }
}

export function* onFetchAllAuthTypes() {
    yield throttleAction(peopleAuthorizedListActions.fetchAuthTypeList, fetchAllAuthTypes);
}

export function* fetchPersonAuthTypes({ payload: dataPayload }) {
    try {
        if (dataPayload.personId) {
            const response = yield axiosLasta(
                `/client/authorization-type/authorized-person/${dataPayload.personId}/location/${dataPayload.locationId}`
            );
            const { data } = response;
            yield put(peopleAuthorizedListActions.fetchAuthorizedPersonAlerts(data));
        }
    } catch (err) {
        const formattedError = getFormattedErrorMessageFromErrorObject(err);
        yield notificationsHandler({ title: formattedError });
    }
}

export function* onFetchPersonAuthTypes() {
    yield throttleAction(peopleAuthorizedListActions.fetchPersonAuthTypesStart, fetchPersonAuthTypes);
}

export function* deleteAuthPerson({ payload: dataPayload }) {
    try {
        let message;
        const response = yield axiosLasta.delete(
            `/client/access-personnel/authorized-person/${dataPayload.personId}/location/${dataPayload.location.id}`
        );

        ({ message } = response.data);

        if (message.includes('SUCCESS')) {
            yield put(peopleAuthorizedListActions.fetchAuthorizedPersonPerLocation(dataPayload.location));
            yield put(peopleAuthorizedListActions.resetAuthPersonAlerts());
            yield put(peopleAuthorizedListActions.setSelectedAlert(null));
            yield put(peopleAuthorizedListActions.deleteSuccess());
            yield notificationsHandler({
                title: 'Successfully removed person',
                variant: 'success',
            });
        }
    } catch (err) {
        yield notificationsHandler({ title: 'Error removing person', err });
    }
}

export function* onDeleteAuthPerson() {
    yield throttleAction(peopleAuthorizedListActions.deleteAuthPerson, deleteAuthPerson);
}

export function* deleteAuthType({ payload: dataPayload }) {
    try {
        let message;
        const getAuthTypeIds = {
            personId: dataPayload.personId,
            locationId: dataPayload.location.id,
        };
        const response = yield axiosLasta.delete(
            `/client/access-personnel/authorized-person/${dataPayload.personId}/location/${dataPayload.location.id}/auth-type/${dataPayload.authType.id}`
        );

        ({ message } = response.data);

        if (message.includes('SUCCESS')) {
            yield put(peopleAuthorizedListActions.fetchPersonAuthTypesStart(getAuthTypeIds));
            yield put(peopleAuthorizedListActions.fetchAuthorizedPersonPerLocation(dataPayload.location));
            yield notificationsHandler({
                title: 'Successfully removed authorization',
                variant: 'success',
            });
        }
    } catch (err) {
        yield notificationsHandler({
            title: 'Error removing authorization',
            err,
        });
    }
}

export function* onDeleteAuthType() {
    yield throttleAction(peopleAuthorizedListActions.deleteAuthType, deleteAuthType);
}
export function* peopleAuthorizedListSaga() {
    yield all([
        call(onFetchAllLocations),
        call(onFetchingAuthorizedPersonPerLocation),
        call(onSubmitFormStart),
        call(onFetchingPersonsStart),
        call(onAddPerson),
        call(onAddSelectedPersonToAuthorizedList),
        call(onFetchAllAuthTypes),
        call(onFetchPersonAuthTypes),
        call(onDeleteAuthPerson),
        call(onDeleteAuthType),
    ]);
}
