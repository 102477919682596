import { axiosLasta } from 'config';

export async function saveDynamicForm(dynamicForm) {
    if (!dynamicForm.id) {
        return addDynamicForm(dynamicForm);
    } else {
        return editDynamicForm(dynamicForm);
    }
}

async function addDynamicForm(dynamicForm) {
    const response = await axiosLasta.post('dynamic-form', dynamicForm);
    return response.data;
}

async function editDynamicForm(dynamicForm) {
    const response = await axiosLasta.put('dynamic-form', dynamicForm);
    return response.data;
}

export async function findAllDynamicForms() {
    const response = await axiosLasta.post('dynamic-form/search', {});
    return response.data;
}

export async function deleteDynamicFormById(id) {
    await axiosLasta.delete(`dynamic-form/${id}`);
}
