import { useFormContext, Controller } from 'react-hook-form';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DATE_TIME_FORMAT } from 'utils/dateTimeHelpers';
import { useValidation } from 'features/dynamicForm/hooks/useValidation';
import { useAutofill } from 'features/dynamicForm/hooks/useAutofill';
import { useIsShown } from 'features/dynamicForm/hooks/useIsShown';
import { useIsRequired } from 'features/dynamicForm/hooks/useIsRequired';
import { dateTimeFieldType } from 'features/dynamicForm/types';

export default function DateTimeField({ field }) {
    const { control } = useFormContext();

    useAutofill(field);
    const validation = useValidation(field);
    const isShown = useIsShown(field);
    const isRequired = useIsRequired(field);

    if (!isShown) {
        return null;
    }

    return (
        <Controller
            control={control}
            name={field.key}
            rules={validation}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <DateTimePicker
                    id={'input_' + field.key}
                    name={field.key}
                    label={field.label}
                    readOnly={field.readonly}
                    format={DATE_TIME_FORMAT}
                    maxDate={new Date(field.maxDate)}
                    minDate={new Date(field.minDate)}
                    onChange={(value) => {
                        onChange(value);
                    }}
                    value={value ?? null}
                    slotProps={{
                        textField: {
                            size: 'small',
                            required: isRequired,
                            InputLabelProps: { shrink: true },
                            error: !!error,
                            helperText: error?.message,
                        },
                    }}
                />
            )}
        />
    );
}

DateTimeField.propTypes = {
    field: dateTimeFieldType,
};
