import { axiosLasta } from 'config';

export async function saveSectionTemplate(sectionTemplate) {
    if (!sectionTemplate.id) {
        return addSectionTemplate(sectionTemplate);
    } else {
        return editSectionTemplate(sectionTemplate);
    }
}

async function addSectionTemplate(sectionTemplate) {
    const response = await axiosLasta.post('dynamic-form/section-template', sectionTemplate);
    return response.data;
}

async function editSectionTemplate(sectionTemplate) {
    const response = await axiosLasta.put('dynamic-form/section-template', sectionTemplate);
    return response.data;
}

export async function findAllSectionTemplates() {
    const response = await axiosLasta.post('dynamic-form/section-template/search', {});
    return response.data;
}

export async function deleteSectionTemplateById(id) {
    await axiosLasta.delete(`dynamic-form/section-template/${id}`);
}
