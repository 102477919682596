import { useFormContext } from 'react-hook-form';
import { TextField as TextFieldMaterial } from '@mui/material';
import { useValidation } from 'features/dynamicForm/hooks/useValidation';
import { useAutofill } from 'features/dynamicForm/hooks/useAutofill';
import { useIsShown } from 'features/dynamicForm/hooks/useIsShown';
import { useIsRequired } from 'features/dynamicForm/hooks/useIsRequired';
import { textFieldType } from 'features/dynamicForm/types';

export default function TextField({ field }) {
    const {
        register,
        formState: { errors },
    } = useFormContext();

    useAutofill(field);
    const validation = useValidation(field);
    const isShown = useIsShown(field);
    const isRequired = useIsRequired(field);

    if (!isShown) {
        return null;
    }

    return (
        <TextFieldMaterial
            id={'input_' + field.key}
            name={field.key}
            type="text"
            label={field.label}
            size="small"
            {...register(field.key, validation)}
            required={isRequired}
            inputProps={{
                readOnly: field.readonly,
                minLength: field.minLength,
            }}
            InputLabelProps={{ shrink: true }}
            error={!!errors[field.key]}
            helperText={errors[field.key]?.message}
        />
    );
}

TextField.propTypes = {
    field: textFieldType,
};
