import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext, Controller } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';
import { dynamicFormActions } from 'features/dynamicForm/store/dynamicFormSlice';
import { useValidation } from 'features/dynamicForm/hooks/useValidation';
import { useIsShown } from 'features/dynamicForm/hooks/useIsShown';
import { useIsRequired } from 'features/dynamicForm/hooks/useIsRequired';
import { getFunctions } from 'features/dynamicForm/services/dropdownApiService.js';
import { dropdownApiFieldType } from 'features/dynamicForm/types';

export default function DropdownApiField({ field }) {
    const [options, setOptions] = useState([]);
    const { control } = useFormContext();
    const dispatch = useDispatch();

    const validation = useValidation(field);
    const isShown = useIsShown(field);
    const isRequired = useIsRequired(field);

    const getOptions = getFunctions(field.dropdownApiType);

    useEffect(() => {
        async function fetchData() {
            const options = await getOptions();
            setOptions(options);
        }

        fetchData();
    }, [getOptions]);

    if (!isShown) {
        return null;
    }

    return (
        <Controller
            control={control}
            name={field.key}
            rules={validation}
            render={({ field: { onChange }, fieldState: { error } }) => (
                <Autocomplete
                    options={options}
                    getOptionKey={(option) => option.dropdownOptionKey}
                    getOptionLabel={(option) => option.dropdownOptionLabel}
                    renderInput={(params) => (
                        <TextField
                            id={'input_' + field.key}
                            name={field.key}
                            label={field.label}
                            {...params}
                            required={isRequired}
                            inputProps={{
                                ...params.inputProps,
                                readOnly: field.readonly,
                                autoComplete: 'off',
                            }}
                            InputLabelProps={{ shrink: true }}
                            error={!!error}
                            helperText={error?.message}
                        />
                    )}
                    onChange={(event, value, reason) => {
                        onChange(value);
                        dispatch(dynamicFormActions.setValue({ key: field.key, value: value }));
                    }}
                />
            )}
        />
    );
}

DropdownApiField.propTypes = {
    field: dropdownApiFieldType,
};
