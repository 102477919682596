import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { saveFieldsTemplate } from 'features/dynamicForm/services/fieldsTemplateService';
import { TextField, Button, IconButton, FormHelperText } from '@mui/material';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import { fieldsTemplateType } from 'features/dynamicForm/types';
import { showNotification } from 'utils/notificationsHandler';
import styles from './AddEditFieldsTemplate.module.css';

const emptyFieldsTemplate = {
    name: '',
    isTemplate: false,
    form: undefined,
};

export default function AddEditFieldsTemplate({ fieldsTemplate, addEditDone, confirmClose }) {
    fieldsTemplate = fieldsTemplate ?? emptyFieldsTemplate;

    const {
        register,
        reset,
        handleSubmit,
        setError,
        clearErrors,
        setValue,
        formState: { errors, isDirty },
    } = useForm({ defaultValues: emptyFieldsTemplate });

    const [form, setForm] = useState(JSON.stringify(fieldsTemplate.form, null, 2));

    useEffect(() => {
        reset({ ...fieldsTemplate, form: form });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        confirmClose(isDirty);
    }, [confirmClose, isDirty]);

    function prettify() {
        clearErrors('form');
        try {
            const tempForm = JSON.parse(form);
            const tempFormString = JSON.stringify(tempForm, null, 2);
            setForm(tempFormString);
        } catch (err) {
            setError('form', {
                type: 'custom',
                message: err.message,
            });
        }
    }

    async function submitHandler(data) {
        try {
            data.form = JSON.parse(data.form);
        } catch (err) {
            setError('form', {
                type: 'custom',
                message: err.message,
            });
        }

        try {
            await saveFieldsTemplate(data);
        } catch (err) {
            const errorsData = err.response?.data;
            if (errorsData) {
                for (const [key, value] of Object.entries(errorsData)) {
                    if (key.startsWith('name')) {
                        setError('name', {
                            type: 'server',
                            message: value,
                        });
                    } else if (key.startsWith('form')) {
                        const formPrefix = 'form';
                        const prefix = key.startsWith(formPrefix) ? key.substring(formPrefix.length).concat(' ') : '';
                        setError('form', {
                            type: 'server',
                            message: prefix + value,
                        });
                    } else if (key.startsWith('global_')) {
                        setError('root', {
                            type: 'server',
                            message: value,
                        });
                    }
                }
            } else {
                showNotification({ err, title: 'Error saving dynamic form' });
            }
            return;
        }

        showNotification({
            title: 'SUCCESSFULLY SAVED FIELDS TEMPLATE',
            variant: 'success',
        });

        addEditDone();
    }

    return (
        <form onSubmit={handleSubmit(submitHandler)} noValidate>
            {errors['root'] && <FormHelperText error>{errors['root']?.message}</FormHelperText>}
            <div>
                <TextField
                    className={styles['margin-top']}
                    label="Name"
                    type="text"
                    size="small"
                    {...register('name', {
                        required: 'Name is required',
                        maxLength: {
                            value: 255,
                            message: 'Name must not exceed 255 characters.',
                        },
                    })}
                    inputProps={{
                        maxLength: 255,
                    }}
                    required
                    error={!!errors['name']}
                    helperText={errors['name']?.message}
                />
            </div>
            <div>
                <TextField
                    label="Form"
                    type="text"
                    multiline
                    {...register('form', { required: 'Form is required' })}
                    value={form}
                    onChange={(e) => {
                        setForm(e.target.value);
                        setValue('form', e.target.value, { shouldDirty: true });
                    }}
                    InputProps={{
                        endAdornment: (
                            <IconButton onClick={prettify}>
                                <FormatIndentIncreaseIcon />
                            </IconButton>
                        ),
                    }}
                    rows={24}
                    sx={{ width: '800px', marginTop: 1 }}
                    required
                    error={!!errors['form']}
                    helperText={errors['form']?.message}
                />
            </div>
            <Button className={styles['margin-top']} variant="contained" color="success" size="small" type="submit">
                submit
            </Button>
        </form>
    );
}

AddEditFieldsTemplate.propTypes = {
    fieldsTemplate: fieldsTemplateType,
    addEditDone: PropTypes.func,
    confirmClose: PropTypes.func,
};
