import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { dynamicFormValuesSelector } from 'features/dynamicForm/store/dynamicFormSelectors';
import { FieldType } from 'features/dynamicForm/types';

export function useAutofill(field) {
    const { setValue } = useFormContext();
    const dynamicFormValues = useSelector(dynamicFormValuesSelector);
    const refValue = dynamicFormValues[field.autofill?.refKey];
    const isRendered = useRef(false);

    useEffect(() => {
        if (isRendered.current && field.autofill) {
            let autofillValue;

            if (field.autofill.valuePath && typeof refValue === 'object' && !Array.isArray(refValue) && refValue !== null) {
                autofillValue = refValue[field.autofill.valuePath];
                if (field.autofill.mappings) {
                    autofillValue = field.autofill.mappings[autofillValue];
                }
            } else if (field.autofill.mappings) {
                autofillValue = field.autofill.mappings[refValue];
            }

            if (field.type === FieldType.DATE_TIME_FIELD && autofillValue) {
                autofillValue = new Date(autofillValue);
            }

            setValue(field.key, autofillValue, { shouldValidate: true });
        }

        isRendered.current = true;
    }, [refValue, field.autofill, field.key, field.type, setValue]);
}
