import { axiosLasta } from 'config';

export async function saveFieldsTemplate(fieldsTemplate) {
    if (!fieldsTemplate.id) {
        return addFieldsTemplate(fieldsTemplate);
    } else {
        return editFieldsTemplate(fieldsTemplate);
    }
}

async function addFieldsTemplate(fieldsTemplate) {
    const response = await axiosLasta.post('dynamic-form/fields-template', fieldsTemplate);
    return response.data;
}

async function editFieldsTemplate(fieldsTemplate) {
    const response = await axiosLasta.put('dynamic-form/fields-template', fieldsTemplate);
    return response.data;
}

export async function findAllFieldsTemplates() {
    const response = await axiosLasta.post('dynamic-form/fields-template/search', {});
    return response.data;
}

export async function deleteFieldsTemplateById(id) {
    await axiosLasta.delete(`dynamic-form/fields-template/${id}`);
}
