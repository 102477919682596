export function isConditionMet(dynamicFormValues, getValues, condition) {
    if (typeof condition.isShown === 'boolean') {
        return condition.isShown === Object.hasOwn(getValues(), condition.refKey);
    } else {
        if (condition.valuePath) {
            const conditionObject = dynamicFormValues[condition.refKey];
            return conditionObject && (conditionObject[condition.valuePath] == condition.value || conditionObject === condition.value);
        } else {
            const value = getValues(condition.refKey);
            return value == condition.value;
        }
    }
}
