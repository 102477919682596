import { axiosLasta } from 'config';
import {
    CACHE_NAME_CLIENT_PERSONNEL_LIST,
    CACHE_NAME_CLIENT_PERSONNEL_LIST_DETAILED,
    CACHE_NAME_CLIENT_PERSONNEL_ID,
} from 'services/constants/cacheNameConstants';

export const fetchClientPersonnel = async () => {
    const url = 'client/personnel/all';
    const { data } = await axiosLasta(url, {
        cache: {
            enabled: true,
            key: CACHE_NAME_CLIENT_PERSONNEL_LIST,
        },
    });
    return data || [];
};

export const fetchAuthorizableClientPersonnel = async (locationId) => {
    const url = `client/personnel/authorizable?locationId=${locationId}`;
    const { data } = await axiosLasta(url);
    return data || [];
};

export const fetchClientPersonnelDetailed = async ({ page = 0, size = 25, filters = {} } = {}) => {
    const url = `client/personnel/all/detailed?page=${page}&size=${size}`;
    const { data } = await axiosLasta.post(url, filters, {
        cache: {
            prune: [CACHE_NAME_CLIENT_PERSONNEL_LIST_DETAILED],
        },
    });
    return data || [];
};

export const createClientPersonnel = async (payload) => {
    const response = await axiosLasta.post('client/personnel', payload, {
        cache: {
            prune: [CACHE_NAME_CLIENT_PERSONNEL_LIST],
        },
    });
    const { data } = response;
    return data;
};

export const editClientPersonnel = async (payload) => {
    const response = await axiosLasta.put('client/personnel', payload, {
        cache: {
            prune: [CACHE_NAME_CLIENT_PERSONNEL_LIST],
        },
    });
    const { data } = response;
    return data;
};

export const fetchPersonnelAuthorizedLocations = async (personnelId) => {
    const url = `client/access-personnel/${personnelId}/locations`;
    const { data } = await axiosLasta(url);
    return data || [];
};

export const fetchClientPersonnelById = async (id) => {
    const url = `client/personnel/id/${id}`;
    const { data } = await axiosLasta(url, {
        cache: {
            prune: [CACHE_NAME_CLIENT_PERSONNEL_ID],
        },
    });
    return data || [];
};
