import { DropdownApiType } from 'features/dynamicForm/types';

export function getFunctions(type) {
    switch (type) {
        case DropdownApiType.PRODUCTS:
            return getProducts;
        case DropdownApiType.MOVIES:
            return getMovies;
        case DropdownApiType.USERS:
            return getUsers;
        default:
            throw new Error('Wrong DropdownApiField type ' + type);
    }
}

async function getProducts() {
    const response = await fetch('https://www.freetestapi.com/api/v1/products');
    const list = await response.json();
    return list.map((item) => {
        return { ...item, dropdownOptionKey: item.id, dropdownOptionLabel: item.name };
    });
}

async function getMovies() {
    const response = await fetch('https://www.freetestapi.com/api/v1/movies');
    const list = await response.json();
    return list.map((item) => {
        return { ...item, dropdownOptionKey: item.id, dropdownOptionLabel: item.title };
    });
}

async function getUsers() {
    const response = await fetch('https://www.freetestapi.com/api/v1/users');
    const list = await response.json();
    return list.map((item) => {
        return { ...item, dropdownOptionKey: item.id, dropdownOptionLabel: item.name };
    });
}
