import { axiosLasta } from 'config';
import { DATA_TABLE_DEFAULT_PAGE_SIZE, FEATURE_LEVEL, FILE_FORMAT } from 'utils/constants';
import { toQueryString } from 'utils/queryStringHelpers';

export const fetchUserAndPermissionListForAdmin = async (pagingConfig) => {
    const { pageIndex, pageSize, searchValue, sortField, sortOrder, filters } = pagingConfig ?? {};
    const params = toQueryString({
        page: pageIndex ?? 0,
        size: pageSize ?? DATA_TABLE_DEFAULT_PAGE_SIZE,
        hasUserRole: filters?.hasUserRole,
        searchText: searchValue,
        sortBy: sortField,
        sortOrder,
    });
    const response = await axiosLasta(`/user-permission-granting-candidates?${params}`);
    const data = response?.data;

    return {
        items: data?.items ?? [],
        totalItems: data?.totalItems ?? 0,
    };
};

export const fetchPermissionPresets = async () => {
    const response = await axiosLasta.get('/permission-presets');
    return response?.data ?? [];
};

export const fetchFeatures = async () => {
    const response = await axiosLasta.get(`/features/level?level=${FEATURE_LEVEL.CLIENT}`);
    return response?.data ?? [];
};

export const fetchFeaturesByCurrentUserRole = async () => {
    const response = await axiosLasta.get('/features/current-user-role');
    return response?.data ?? [];
};

export const grantExternalUsersRole = async (userId) => {
    const response = await axiosLasta.post(`/app-roles/external-users/${userId}`);
    return response?.data;
};

export const revokeUserAccessingToApp = async (userId) => {
    const response = await axiosLasta.delete(`/app-roles/by-user-id/${userId}`);
    return response?.data;
};

export const inviteUserPermission = async (data) => {
    const response = await axiosLasta.post('/user-permission-granting-candidates', data);
    return response?.data;
};

export const fetchUserPermissionDetail = async (userId) => {
    const response = await axiosLasta.get(`/user-permission-granting-candidates/user-id/${userId}`);
    return response?.data;
};

export const updateUserPermission = async (userId, data) => {
    const response = await axiosLasta.put(`/user-permission-granting-candidates/user-id/${userId}`, data);
    return response?.data;
};

export const getChangeLogs = async () => {
    const response = await axiosLasta.get(`user-permission-granting-candidates/changelogs?limit=5`);
    return response?.data ?? [];
};

export const exportUserPermission = async ({ format, fileName, searchText }) => {
    const params = {
        format,
        searchText,
    };

    const response = await axiosLasta.get('/user-permission-granting-candidates/export', {
        params,
        responseType: 'blob',
    });

    const blob = new Blob([response.data], {
        type:
            format === FILE_FORMAT.PDF
                ? 'application/pdf'
                : format === FILE_FORMAT.XLS
                  ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                  : 'text/csv',
    });

    return { blob, format, fileName };
};
