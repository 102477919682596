import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { Button } from '@mui/material';
import Section from 'features/dynamicForm/components/Section/Section';
import { dynamicFormActions } from 'features/dynamicForm/store/dynamicFormSlice';
import { sectionTemplateType } from 'features/dynamicForm/types';

export default function ViewSectionTemplate({ sectionTemplate }) {
    const methods = useForm({ shouldUnregister: true });
    const dispatch = useDispatch();

    const onSubmit = (data) => {
        console.log('Submit data', data);
        alert(JSON.stringify(data, null, 2));
    };

    useEffect(() => {
        methods.reset();
        dispatch(dynamicFormActions.clearValues());
    }, [dispatch, methods, sectionTemplate]);

    return (
        <div>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
                    <Section section={sectionTemplate?.form} />
                    <Button variant="contained" color="success" size="small" type="submit">
                        submit
                    </Button>
                </form>
            </FormProvider>
        </div>
    );
}

ViewSectionTemplate.propTypes = {
    sectionTemplate: sectionTemplateType,
};
