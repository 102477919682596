import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { dynamicFormValuesSelector } from 'features/dynamicForm/store/dynamicFormSelectors';
import { isConditionMet } from 'features/dynamicForm/utils/conditionHelper';

export function useIsShown(field) {
    const dynamicFormValues = useSelector(dynamicFormValuesSelector);
    const { getValues } = useFormContext();

    return !field.showConditions || field.showConditions?.some((condition) => isConditionMet(dynamicFormValues, getValues, condition));
}
