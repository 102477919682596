import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    submittingFormInProgress: false,
    submittingFormError: null,

    masterList: [],
    selectedMasterList: null,

    organizations: [],
    dynamicForms: [],

    formStatus: 'viewOnly',
    beforeEditData: null,

    removePopupVisible: false,
    addPopupVisible: false,
    popupVisible: null,
};

const locationListSlice = createSlice({
    name: 'locationList',
    initialState,
    reducers: {
        fetchMasterListStart() {},
        fetchMasterListSuccess(state, { payload: companyList }) {
            state.masterList = companyList;
        },
        fetchOrganizations() {},
        fetchOrganizationsSuccess(state, { payload }) {
            state.organizations = payload;
        },
        fetchDynamicForms() {},
        fetchDynamicFormsSuccess(state, { payload }) {
            state.dynamicForms = payload;
        },
        setSelectedMasterListItem(state, { payload }) {
            state.selectedMasterList = payload ? state.masterList.find((comapny) => comapny.id === payload) : null;
        },
        submitFormStart(state) {
            state.submittingFormError = null;
        },
        submitFormFailed(state, { payload: error }) {
            state.submittingFormError = error;
        },
        setFormStatus(state, { payload: formStatus }) {
            state.formStatus = formStatus;
        },
        setBeforeEditData(state, { payload: personData = null }) {
            state.beforeEditData = personData;
        },
        resetState(state) {
            state.submittingFormInProgress = false;
            state.submittingFormError = null;
            state.formStatus = 'viewOnly';
            state.masterList = [];
            state.selectedMasterList = null;
            state.beforeEditData = null;
            state.selectedMasterList = null;
        },
        setRemovePopupVisible(state, { payload }) {
            state.removePopupVisible = payload;
        },
        setAddPopupVisible(state, { payload }) {
            state.addPopupVisible = payload;
        },
        addItemToMasterList() {},
        setPopupVisible(state, { payload: visible }) {
            state.popupVisible = visible;
        },
    },
});

export const locationListActions = locationListSlice.actions;
export const locationListReducer = locationListSlice.reducer;
