import PropTypes from 'prop-types';
import TextField from 'features/dynamicForm/components/TextField/TextField';
import TextArea from 'features/dynamicForm/components/TextArea/TextArea';
import NumberField from 'features/dynamicForm/components/NumberField/NumberField';
import DateTimeField from 'features/dynamicForm/components/DateTimeField/DateTimeField';
import DropdownField from 'features/dynamicForm/components/DropdownField/DropdownField';
import DropdownApiField from 'features/dynamicForm/components/DropdownApiField/DropdownApiField';
import { FieldType } from 'features/dynamicForm/types';

export default function Field({ field }) {
    function renderField(field) {
        switch (field.type) {
            case FieldType.TEXT_FIELD:
                return <TextField field={field} />;
            case FieldType.TEXT_AREA:
                return <TextArea field={field} />;
            case FieldType.NUMBER_FIELD:
                return <NumberField field={field} />;
            case FieldType.DATE_TIME_FIELD:
                return <DateTimeField field={field} />;
            case FieldType.DROPDOWN_FIELD:
            case FieldType.YES_NO_FIELD:
                return <DropdownField field={field} />;
            case FieldType.DROPDOWN_API_FIELD:
                return <DropdownApiField field={field} />;
            default:
                throw new Error('Wrong field type: ' + field.type);
        }
    }

    return renderField(field);
}

Field.propTypes = {
    field: PropTypes.object,
};
