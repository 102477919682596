import { useIsRequired } from 'features/dynamicForm/hooks/useIsRequired';
import { FieldType } from 'features/dynamicForm/types';

export const useValidation = (field) => {
    const validationObject = {};
    const isRequired = useIsRequired(field);

    validationObject.validate = (value) => {
        if (!isRequired || value || (field.type === FieldType.YES_NO_FIELD && value === false)) {
            return true;
        }
        return `${field.label} is required`;
    };
    if (field.minLength) {
        validationObject.minLength = {
            value: field.minLength,
            message: `${field.label} must be at least ${field.minLength} characters`,
        };
    }
    if (field.maxLength) {
        validationObject.maxLength = {
            value: field.maxLength,
            message: `${field.label} cannot exceed ${field.maxLength} characters`,
        };
    }
    if (field.minimum) {
        validationObject.min = {
            value: field.minimum,
            message: `${field.label} must be greater then ${field.minimum}`,
        };
    }
    if (field.maximum) {
        validationObject.max = {
            value: field.maximum,
            message: `${field.label} must be less then ${field.maximum}`,
        };
    }
    if (field.pattern) {
        validationObject.pattern = {
            value: new RegExp(field.pattern),
            message: 'Invalid format',
        };
    }

    return validationObject;
};
