import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { dynamicFormValuesSelector } from 'features/dynamicForm/store/dynamicFormSelectors';
import { isConditionMet } from 'features/dynamicForm/utils/conditionHelper';

export function useIsRequired(field) {
    const dynamicFormValues = useSelector(dynamicFormValuesSelector);
    const { getValues, clearErrors } = useFormContext();

    const isRequired = field.required || field.requiredConditions?.some((condition) => isConditionMet(dynamicFormValues, getValues, condition));

    useEffect(() => {
        clearErrors(field.key);
    }, [clearErrors, field.key, isRequired]);

    return isRequired;
}
