import PropTypes from 'prop-types';

const { shape, number, string, bool, object, array, any, arrayOf } = PropTypes;

export const dynamicFormType = shape({
    id: number,
    name: string,
    isTemplate: bool,
    form: object,
});

export const sectionTemplateType = shape({
    id: number,
    name: string,
    form: object,
});

export const fieldsTemplateType = shape({
    id: number,
    name: string,
    form: array,
});

export const sectionType = shape({
    key: string,
    title: string,
    fields: array,
    sections: array,
    showConditions: array,
});

const conditionType = shape({
    refKey: string,
    isShown: bool,
    valuePath: string,
    value: any,
});

const defaultFieldType = {
    type: string,
    key: string,
    label: string,
    required: bool,
    readonly: bool,
    tooltip: string,
    showConditions: arrayOf(conditionType),
    requiredConditions: arrayOf(conditionType),
    autofill: shape({
        refKey: string,
        valuePath: string,
        mappings: object,
    }),
    mappingColumn: string,
};

export const dateTimeFieldType = shape({
    ...defaultFieldType,
    maxDate: string,
    minDate: string,
});

export const dropdownApiFieldType = shape({
    ...defaultFieldType,
    dropdownApiType: string,
});

export const dropdownFieldType = shape({
    ...defaultFieldType,
    options: arrayOf(
        shape({
            label: string,
            value: any,
        })
    ),
});

export const numberFieldType = shape({
    ...defaultFieldType,
    minimum: number,
    maximum: number,
    step: number,
});

export const textFieldType = shape({
    ...defaultFieldType,
    minLength: number,
    maxLength: number,
    pattern: string,
});

export const textAreaType = shape({
    ...defaultFieldType,
    minLength: number,
    maxLength: number,
    pattern: string,
    rows: number,
    columns: number,
});

export const FieldType = Object.freeze({
    TEXT_FIELD: 'TEXT_FIELD',
    TEXT_AREA: 'TEXT_AREA',
    NUMBER_FIELD: 'NUMBER_FIELD',
    DATE_TIME_FIELD: 'DATE_TIME_FIELD',
    DROPDOWN_FIELD: 'DROPDOWN_FIELD',
    DROPDOWN_API_FIELD: 'DROPDOWN_API_FIELD',
    YES_NO_FIELD: 'YES_NO_FIELD',
});

export const DropdownApiType = Object.freeze({
    PRODUCTS: 'PRODUCTS',
    MOVIES: 'MOVIES',
    USERS: 'USERS',
});
