import { Button, styled } from '@mui/material';

const CustomButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'customVariant',
})(({ customVariant }) => ({
    padding: '0.25rem 1rem',
    minWidth: '100px',
    color: '#fff',
    fontWeight: '700',
    fontSize: '14px',
    textTransform: 'none',
    borderRadius: '0.25rem',
    boxShadow: 'none',
    ...(customVariant === 'primary' && {
        backgroundColor: '#2f80ed',
        '&:hover': {
            backgroundColor: '#1565c0',
        },
    }),
    ...(customVariant === 'secondary' && {
        backgroundColor: '#9c27b0',
        '&:hover': {
            backgroundColor: '#7b1fa2',
        },
    }),
    ...(customVariant === 'success' && {
        backgroundColor: '#219653',
        '&:hover': {
            backgroundColor: '#1c7d46',
        },
        '&:disabled': {
            backgroundColor: '#E0E0E0',
            color: '#A6A6A6',
            cursor: 'not-allowed',
        },
    }),
    ...(customVariant === 'error' && {
        color: '#ef7676',
        border: '1px solid #ef7676',
        backgroundColor: '#FFF',
        '&:hover': {
            backgroundColor: '#fce8e8',
        },
    }),
    ...(customVariant === 'outlined' && {
        backgroundColor: 'transparent',
        color: '#999999',
        '&:hover': {
            backgroundColor: '#f2f2f2',
        },
    }),
    ...(customVariant === 'remove' && {
        backgroundColor: '#ef7676',
        '&:hover': {
            backgroundColor: '#c62828',
        },
        '&:disabled': {
            backgroundColor: '#E0E0E0',
            color: '#A6A6A6',
            cursor: 'not-allowed',
        },
    }),
}));

export const linkButtonStyle = {
    textDecoration: 'underline',
    textTransform: 'none',
    fontWeight: 700,
    padding: 0,
    minWidth: 'auto',
};

export default CustomButton;
